<template>
  <card-component
    title="Edit Profile"
    icon="account-circle"
  >
    <form @submit.prevent="submit">
      <!--      <b-field-->
      <!--        horizontal-->
      <!--        label="Avatar"-->
      <!--      >-->
      <!--        <file-picker type="is-info" />-->
      <!--      </b-field>-->
      <!--      <hr>-->
      <b-field
        horizontal
        label="First Name"
        :type="{ 'is-danger': errors.first_name }"
        :message="{ [errors.first_name ? errors.first_name[0] : '']: errors.first_name }"
      >
        <b-input
          v-model="form.first_name"
          name="first_name"
          required
        />
      </b-field>
      <b-field
        horizontal
        label="Middle Name"
        :type="{ 'is-danger': errors.middle_name }"
        :message="{ [errors.first_name ? errors.middle_name[0] : '']: errors.middle_name }"
      >
        <b-input
          v-model="form.middle_name"
          name="middle_name"
        />
      </b-field>
      <b-field
        horizontal
        label="Last Name"
        :type="{ 'is-danger': errors.last_name }"
        :message="{ [errors.last_name ? errors.last_name[0] : '']: errors.last_name }"
      >
        <b-input
          v-model="form.last_name"
          name="last_name"
          required
        />
      </b-field>
      <b-field
        horizontal
        label="E-mail"
        :type="{ 'is-danger': errors.email }"
        :message="{ [errors.email ? errors.email[0] : '']: errors.email }"
      >
        <b-input
          v-model="form.email"
          name="email"
          type="email"
          required
        />
      </b-field>
      <b-field
        horizontal
        label="Phone"
        :type="{ 'is-danger': errors.phone }"
        :message="{ [errors.phone ? errors.phone[0] : '']: errors.phone }"
      >
        <vue-tel-input
          v-model="form.phone"
          :only-countries="['KE', 'US']"
          @input="onPhoneInput"
        />
      </b-field>
      <b-field
        horizontal
        label="New password"
        :type="{ 'is-danger': errors.password }"
        :message="{ [errors.password ? errors.password[0] : '']: errors.password }"
      >
        <b-input
          v-model="form.password"
          name="password"
          type="password"
        />
      </b-field>
      <hr>
      <b-field horizontal>
        <b-field grouped>
          <div class="control">
            <b-button
              native-type="submit"
              type="is-success"
              :loading="isLoading"
            >
              Submit
            </b-button>
          </div>
        </b-field>
      </b-field>
    </form>
  </card-component>
</template>

<script>
// import FilePicker from '@/components/FilePicker.vue'
import CardComponent from '@/components/CardComponent.vue'

export default {
  name: 'ProfileUpdateForm',
  components: {
    CardComponent
    // FilePicker
  },
  data () {
    return {
      isLoading: false,
      errors: {},
      form: {
        first_name: this.$auth.user().first_name,
        middle_name: this.$auth.user().middle_name,
        last_name: this.$auth.user().last_name,
        email: this.$auth.user().email,
        phone: this.$auth.user().dial_code + this.$auth.user().phone,
        password: '',
        dial_code: this.$auth.user().dial_code,
        iso_code: this.$auth.user().iso_code,
        phone_number: this.$auth.user().phone
      }
    }
  },
  methods: {
    onPhoneInput (number, phoneObject) {
      if (!phoneObject.valid) {
        this.errors.phone = ['Invalid phone number']
        this.form.dial_code = ''
        this.form.iso_code = ''
      } else {
        this.errors.phone = null
        this.form.dial_code = '+' + phoneObject.countryCallingCode
        this.form.iso_code = phoneObject.countryCode
        this.form.phone_number = phoneObject.nationalNumber
      }
    },
    submit () {
      this.isLoading = true

      this.axios.patch('/v2/auth/user', {
        first_name: this.form.first_name,
        middle_name: this.form.middle_name,
        last_name: this.form.last_name,
        email: this.form.email,
        phone: this.form.phone_number,
        dial_code: this.form.dial_code,
        iso_code: this.form.iso_code,
        password: this.form.password
      }).then(() => {
        this.$auth.fetch()
        this.$buefy.snackbar.open({
          message: 'Profile updated successfully',
          queue: false
        })
        this.isLoading = false
      }).catch(error => {
        this.isLoading = false
        this.errors = error.response.data.errors
      })
    }
  }
}
</script>
<style>
.button.is-success {
  background-color: #17561f !important;
  border-color: transparent;
  color: #fff;
}
</style>
