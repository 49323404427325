<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Profile
    </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <profile-update-form class="tile is-child" />
        <card-component
          title="Profile"
          icon="account"
          class="tile is-child"
        >
          <user-avatar
            class="image has-max-width is-aligned-center"
            :avatar="'https://ui-avatars.com/api/?background=17561f&color=fafbff&name=' + $auth.user().name"
          />
          <hr>
          <b-field label="Name">
            <b-input
              :value="$auth.user().name"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="E-mail">
            <b-input
              :value="$auth.user().email"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="Country">
            <b-input
              :value="$auth.user().iso_code"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="Country Code">
            <b-input
              :value="$auth.user().dial_code"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="Phone">
            <b-input
              :value="$auth.user().phone"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="Role">
            <b-input
              :value="$auth.user().role"
              custom-class="is-static"
              readonly
            />
          </b-field>
        </card-component>
      </tiles>
      <!--      <password-update-form />-->
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CardComponent from '@/components/CardComponent.vue'
import TitleBar from '@/components/TitleBar.vue'
import HeroBar from '@/components/HeroBar.vue'
import ProfileUpdateForm from '@/components/ProfileUpdateForm.vue'
// import PasswordUpdateForm from '@/components/PasswordUpdateForm.vue'
import Tiles from '@/components/Tiles.vue'
import UserAvatar from '@/components/UserAvatar.vue'

export default {
  name: 'Profile',
  components: {
    UserAvatar,
    Tiles,
    // PasswordUpdateForm,
    ProfileUpdateForm,
    HeroBar,
    TitleBar,
    CardComponent
  },
  data () {
    return {
      titleStack: ['Dashboard', 'Profile']
    }
  },
  computed: {
    ...mapState([
      'userName',
      'userEmail'
    ])
  }
}
</script>
